(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-messages/assets/javascripts/player-messages.js') >= 0) return;  svs.modules.push('/components/accountservices/player-messages/assets/javascripts/player-messages.js');
"use strict";


svs.accountservices = svs.accountservices || {};
svs.accountservices.player_messages = svs.accountservices.player_messages || {};

const finishPlayscanNotification = callback => {
  var _svs$accountservices$, _svs$accountservices$2, _svs$accountservices$3;
  const logger = svs.core.log.getLogger('accountservices:player-messages');
  const ACTION_STATE = ((_svs$accountservices$ = svs.accountservices.player_messages) === null || _svs$accountservices$ === void 0 || (_svs$accountservices$ = _svs$accountservices$.data) === null || _svs$accountservices$ === void 0 ? void 0 : _svs$accountservices$.ACTION_STATE) || {};
  const ACTIONS = ((_svs$accountservices$2 = svs.accountservices.player_messages) === null || _svs$accountservices$2 === void 0 || (_svs$accountservices$2 = _svs$accountservices$2.data) === null || _svs$accountservices$2 === void 0 ? void 0 : _svs$accountservices$2.ACTIONS) || {};
  const enabled = ((_svs$accountservices$3 = svs.accountservices.player_messages) === null || _svs$accountservices$3 === void 0 || (_svs$accountservices$3 = _svs$accountservices$3.data) === null || _svs$accountservices$3 === void 0 ? void 0 : _svs$accountservices$3.enabled) || false;

  if (!enabled) {
    callback && typeof callback === 'function' && callback(false);
    return;
  }

  if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    callback && typeof callback === 'function' && callback(false);
    return;
  }
  svs.core.userSession.get('session.actionList', (err, actionList) => {
    if (err) {
      logger.warn('Could not get session.actionList');
    } else {
      logger.debug("session.actionList: ".concat(actionList));
      const playscanAction = actionList.find(action => action.a === ACTIONS.PLAYSCAN_NOTIFICATION);
      playscanAction.s = ACTION_STATE.FINISHED;
      svs.core.userSession.set('session.actionList', actionList, err => {
        if (err) {
          logger.warn("Failed to save actionList: ".concat(actionList, " to userSession"));
        } else {
          logger.debug("Saved actionList: ".concat(actionList, " to userSession"));
        }
        callback && typeof callback === 'function' && callback(true);
      });
    }
  });
};
svs.accountservices.player_messages.finishPlayscanNotification = finishPlayscanNotification;

 })(window);